import {
  FileInputControlled,
  SelectOptionType,
  TextAreaInput,
} from '@octano/global-ui';
import { useCallback, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { Card, Col, Row } from 'reactstrap';

import { SectionTitle } from '../../../../components/text';
import { PetitionStatus } from '../../../../types/studentPetitions';
import FileDownload from '../../parts/FileDownload';
import InputToolTip from '../../parts/InputToolTip';
import {
  FormStudentsPetitionsUpdateData,
  ViewStudentsPetition,
} from '../types/petitionDetail';
import IconOption from './IconOption';

interface PetitionFormProps {
  downloadFile?: ViewStudentsPetition['doc'];
  onlyView?: boolean;
  texts: any;
  lastUpdate: any;
  currentStatus: any;
}

const prefix = 'studentPetitionDetail.received';
export default function StudentPetitionForm({
  downloadFile,
  onlyView = true,
  texts,
  lastUpdate,
  currentStatus,
}: PetitionFormProps) {
  const { t } = useTranslation();
  const optionsSelect: SelectOptionType[] = useMemo((): SelectOptionType[] => {
    const option = Object.keys(PetitionStatus).map((key) => ({
      value: PetitionStatus[key as keyof typeof PetitionStatus],
      label: t(
        `${prefix}.body.inputTooltips.status.${
          PetitionStatus[key as keyof typeof PetitionStatus]
        }`,
      ),
    }));

    return option;
  }, [t]);

  const {
    control,
    setValue,
    watch,
    formState: { isSubmitting },
  } = useFormContext<FormStudentsPetitionsUpdateData>();

  const [selectStatus] = watch(['status']);

  useEffect(() => {
    setValue('status', {
      value: currentStatus,
      label: t(`${prefix}.body.inputTooltips.status.${currentStatus}`),
    });
  }, [currentStatus, setValue, t]);

  const onChangeSelect = useCallback(
    (value: SelectOptionType) => {
      setValue('status', value);
    },
    [setValue],
  );

  return (
    <>
      <Card
        className="petition-card petition-info-resolution mb-4 p-4"
        style={{ boxShadow: '0 0 5px #00000029' }}
      >
        <Row>
          <Col xs={12} className="pb-2">
            <InputToolTip
              prefix={`${prefix}.body.inputTooltips`}
              name={texts.body.currentStatusLabel}
              status={currentStatus}
            />
          </Col>

          <Col xs={12} className="pb-2">
            <p>
              <b>{texts.body.lastUpdateLabel}: </b>
              <span>{lastUpdate}</span>
            </p>
          </Col>

          <Col xs={12} sm={12} md={8} className="pb-2">
            <Card className="d-flex flex-column justify-content-start ">
              <Col xs={12} md={8} lg={6} className="px-0">
                <FileDownload
                  text={texts.download.button}
                  label={texts.download.label}
                  file={downloadFile}
                />
              </Col>
            </Card>
          </Col>

          <Col xs={12} sm={12} md={4} className="pb-2">
            <Card className="d-flex flex-column justify-content-start ">
              <p>
                <b>{texts.body.petitionStatusLabel}:</b>
              </p>
              <Select
                options={optionsSelect}
                value={selectStatus}
                onChange={onChangeSelect}
                styles={{
                  indicatorSeparator: () => ({}),
                  container: (styles) => ({
                    ...styles,
                    width: '100%',
                    margin: '0 auto',
                  }),
                }}
                className="react-select"
                classNamePrefix="react-select"
                name="status"
                components={{ SingleValue: IconOption }}
                isDisabled={onlyView}
              />
            </Card>
          </Col>
        </Row>
      </Card>

      <Card className="petition-comment-resolution mb-4">
        <SectionTitle text={texts.body.petitionCommentLabel} />
        <TextAreaInput
          label=""
          placeholder={texts.body.petitionCommentPlaceHolder}
          name="description"
          onBlur={function noRefCheck() {}}
          onChange={function noRefCheck() {}}
          rules={
            onlyView
              ? {}
              : {
                  required: texts.body.msgValidations.required,
                  maxLength: {
                    value: 255,
                    message: t('common.validations.maxLength', {
                      length: 255,
                    }),
                  },
                }
          }
          height={95}
          control={control}
          disabled={onlyView || isSubmitting}
        />
      </Card>

      {[PetitionStatus.Resolved, PetitionStatus.Rejected].includes(
        selectStatus.value as any,
      ) && (
        <Card className="petition-attach-resolution mb-4">
          <Col xs={12} sm={12} md={4} className="pb-2">
            <FileInputControlled
              name="docResponse"
              btnText={texts.upload.button}
              label={texts.upload.label}
              accept="image/png, image/jpeg, application/pdf"
              control={control}
            />
          </Col>
        </Card>
      )}
    </>
  );
}
